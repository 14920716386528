import React, { useState, useEffect } from 'react';
import './index.css';
import data from './data';
import logo from './logo.png';
import Cart from './cart'; 
import allergens from './allergens'; 

// Importiere die Icons
import softdrinksIcon from './kategorien/softdrinks.png';
import burgerIcon from './kategorien/burger.png';
import dessertIcon from './kategorien/dessserts.png';
import fingerfoodIcon from './kategorien/fingerfood.png';
import friesIcon from './kategorien/frenchfries.png'; 
import hotdogsIcon from './kategorien/hotdogs.png';
import saladIcon from './kategorien/salad.png';
import wrapIcon from './kategorien/wrap.png';
import dipsIcon from './kategorien/dips.png';
import alleIcon from './kategorien/alle.png';
import menuIcon from './kategorien/menu.png';

function App() {
  const [activeTab, setActiveTab] = useState('Burger');
  const [cart, setCart] = useState([]); 
  const [selectedDressing, setSelectedDressing] = useState({}); 
  const [notification, setNotification] = useState(null); // Zustand für die Benachrichtigung
  const [highlightedItem, setHighlightedItem] = useState(null); // Zustand für das hervorgehobene Item
  const darkMode = true;
  const categoryIcons = {
    Softdrinks: softdrinksIcon,
    Burger: burgerIcon,
    Dessert: dessertIcon, 
    Fingerfood: fingerfoodIcon,
    Fries: friesIcon, 
    HotDogs: hotdogsIcon,
    Salat: saladIcon,
    Wraps: wrapIcon,
    Dips: dipsIcon, 
    Alle: alleIcon,
    Menü: menuIcon // Icon für die neue Kategorie hinzugefügt
  };
  const { categories, items } = data;

  const allItems = Object.values(items).flat();

  const variantLabels = ['Single', 'Double', 'Triple'];
  const fingerfoodLabels = ['6er', '10er', '20er'];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Filtere die Kategorien, um "Alle" auszuschließen
  const filteredCategories = categories.filter(category => category !== 'Alle');

  // Setze den Titel dynamisch basierend auf dem aktiven Tab
  useEffect(() => {
    document.title = `Sternburger Menü - ${activeTab}`;
  }, [activeTab]);

  // Wiederherstellen des Warenkorbs aus dem localStorage beim Laden der Seite
  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  // Speichern des Warenkorbs im localStorage, wenn sich der Zustand des Warenkorbs ändert
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Funktion zum Hinzufügen eines Items zum Warenkorb
  const addToCart = (item, selectedPrice, variantLabel) => {
    const dressing = selectedDressing[item.name] || '';
    const option = selectedDressing[`${item.name}-option`] || '';
    const softdrink = selectedDressing[`${item.name}-softdrink`] || '';
    const optionPrice = item.options ? item.options.find(opt => opt.name === option)?.price || '0' : '0';
    const softdrinkPrice = item.softdrinks ? item.softdrinks.find(drink => drink.name === softdrink)?.price || '0' : '0';
    const totalPrice = (parseFloat(selectedPrice) + parseFloat(optionPrice.replace(',', '.')) + parseFloat(softdrinkPrice.replace(',', '.'))).toFixed(2);

    const updatedCart = [...cart];
    const existingItemIndex = updatedCart.findIndex(cartItem => 
      cartItem.name === item.name && 
      cartItem.selectedPrice === totalPrice && 
      cartItem.dressing === dressing && 
      cartItem.option === option && 
      cartItem.softdrink === softdrink
    );

    if (existingItemIndex !== -1) {
      updatedCart[existingItemIndex].quantity += 1;
    } else {
      updatedCart.push({ ...item, selectedPrice: totalPrice, dressing, option, softdrink, quantity: 1, variantLabel });
    }

    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Speichern im localStorage
    setNotification(`${item.name} wurde zum Warenkorb hinzugefügt`);
    setHighlightedItem(item.name); // Setze das hervorgehobene Item
    setTimeout(() => {
      setNotification(null);
      setHighlightedItem(null); // Entferne das hervorgehobene Item nach 3 Sekunden
    }, 3000); // Benachrichtigung nach 3 Sekunden zurücksetzen
  };

  // Funktion zum Entfernen eines Items aus dem Warenkorb
  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
    } else {
      updatedCart.splice(index, 1);
    }
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Speichern im localStorage
  };

  const selectDressing = (itemName, dressing) => {
    setSelectedDressing({ ...selectedDressing, [itemName]: dressing });
  };

  const selectOption = (itemName, option, type) => {
    setSelectedDressing({ ...selectedDressing, [`${itemName}-${type}`]: option });
  };

  return (
    <div className={`${darkMode ? 'dark' : ''}`}>
      <div className="min-h-screen p-4">
        <div className="w-full max-w-4xl mx-auto">
          <div className="flex justify-between mb-4">
            <div className="flex flex-wrap justify-center">
              {filteredCategories.map((category) => (
                <button
                  key={category}
                  className={`tab px-4 py-2 mx-1 my-1 rounded transition-all duration-500 ease-in-out ${activeTab === category ? 'bg-primary text-secondary' : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-200'}`}
                  onClick={() => setActiveTab(category)}
                >
                  <div className="flex flex-col items-center">
                    <img src={categoryIcons[category]} alt={category} className="h-12 w-12" />
                    <span className="text-xs mt-1">{category}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-full bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-all duration-500 ease-in-out">
              <div className="flex items-center justify-center mb-4">
                <img src={logo} alt="Logo" className="h-12 w-12 mr-2" />
                <h1 className="text-3xl font-bold text-center text-primary dark:text-secondary">{activeTab}</h1>
              </div>
              <ul>
                {(activeTab === 'Alle' || activeTab === 'Burger') && (
                  <li className="bg-white dark:bg-gray-700 p-4 mb-4 rounded-lg shadow-md flex flex-col sm:flex-row justify-between items-start sm:items-center transition-all duration-500 ease-in-out">
                    <div className="flex-1">
                      <h5 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Burger Varianten
                      </h5>
                    </div>
                    <div className="flex space-x-2 mt-2 sm:mt-0 flex-wrap">
                      {variantLabels.map((label, idx) => (
                        <div key={idx} className="bg-secondary text-white px-2 py-1 rounded whitespace-nowrap">
                          {label}
                        </div>
                      ))}
                    </div>
                  </li>
                )}
                {(activeTab === 'Fingerfood') && (
                  <li className="bg-white dark:bg-gray-700 p-4 mb-4 rounded-lg shadow-md flex flex-col sm:flex-row justify-between items-start sm:items-center transition-all duration-500 ease-in-out">
                    <div className="flex-1">
                      <h5 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Finger Food Varianten
                      </h5>
                    </div>
                    <div className="flex space-x-2 mt-2 sm:mt-0 flex-wrap">
                      {fingerfoodLabels.map((label, idx) => (
                        <div key={idx} className="bg-secondary text-white px-2 py-1 rounded whitespace-nowrap">
                          {label}
                        </div>
                      ))}
                    </div>
                  </li>
                )}
                {(activeTab === 'Alle' ? allItems : items[activeTab]).map((item, index) => (
                  <li key={index} className={`bg-white dark:bg-gray-700 p-4 mb-4 rounded-lg shadow-md flex flex-col sm:flex-row justify-between items-start sm:items-center transition-all duration-500 ease-in-out ${highlightedItem === item.name ? 'highlight' : ''}`}>
                    <div className="flex-1">
                      <h5 className="text-xl font-semibold text-gray-900 dark:text-white">
                        {item.name}
                        {item.allergens && (
                          <sup className="allergens text-xs text-red-500 dark:text-red-300 ml-1">{item.allergens}</sup>
                        )}
                      </h5>
                      <p className="text-gray-700 dark:text-gray-300">{item.description}</p>
                      {item.dressings && (
                        <div className="mt-2">
                          <p className="text-gray-700 dark:text-gray-300">Wähle dein Dressing:</p>
                          <div className="flex flex-wrap mt-1">
                            {item.dressings.map((dressing, idx) => (
                              <button
                                key={idx}
                                onClick={() => selectDressing(item.name, dressing)}
                                className={`dressing-button px-2 py-1 m-1 rounded-full whitespace-nowrap ${selectedDressing[item.name] === dressing ? 'selected' : ''}`}
                              >
                                {dressing}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                      {item.options && (
                        <div className="mt-2">
                          <p className="text-gray-700 dark:text-gray-300">Wähle deine Option:</p>
                          <div className="flex flex-wrap mt-1">
                            {item.options.map((option, idx) => (
                              <button
                                key={idx}
                                onClick={() => selectOption(item.name, option.name, 'option')}
                                className={`dressing-button px-2 py-1 m-1 rounded-full whitespace-nowrap ${selectedDressing[`${item.name}-option`] === option.name ? 'selected' : ''}`}
                              >
                                {option.name} +{option.price} €
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                      {item.softdrinks && (
                        <div className="mt-2">
                          <p className="text-gray-700 dark:text-gray-300">Wähle dein Softdrink:</p>
                          <div className="flex flex-wrap mt-1">
                            {item.softdrinks.map((drink, idx) => (
                              <button
                                key={idx}
                                onClick={() => selectOption(item.name, drink.name, 'softdrink')}
                                className={`dressing-button px-2 py-1 m-1 rounded-full whitespace-nowrap ${selectedDressing[`${item.name}-softdrink`] === drink.name ? 'selected' : ''}`}
                              >
                                {drink.name} +{drink.price} €
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex space-x-2 mt-2 sm:mt-0 flex-wrap">
                      {item.price.split('/').map((price, idx) => {
                        const option = selectedDressing[`${item.name}-option`] || '';
                        const softdrink = selectedDressing[`${item.name}-softdrink`] || '';
                        const optionPrice = item.options ? item.options.find(opt => opt.name === option)?.price || '0' : '0';
                        const softdrinkPrice = item.softdrinks ? item.softdrinks.find(drink => drink.name === softdrink)?.price || '0' : '0';
                        const totalPrice = (parseFloat(price) + parseFloat(optionPrice.replace(',', '.')) + parseFloat(softdrinkPrice.replace(',', '.'))).toFixed(2);
                        return (
                          <div key={idx} className="flex flex-col items-center">
                            <button
                              onClick={() => addToCart(item, price, (activeTab === 'Fingerfood' || activeTab === 'Burger') ? (activeTab === 'Fingerfood' ? fingerfoodLabels[idx] : variantLabels[idx]) : '')}
                              className={`button price bg-secondary text-white px-1 py-1 rounded whitespace-nowrap ${isNaN(parseFloat(price)) || price === '-' ? 'cursor-not-allowed opacity-50' : ''}`}
                              disabled={isNaN(parseFloat(price)) || price === '-'}
                            >
                              {totalPrice} €
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-8 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-2 text-primary dark:text-secondary">Allergene und Zusatzstoffe</h2>
            <ul className="text-gray-700 dark:text-gray-300 flex flex-wrap">
              {allergens.map((allergen) => (
                <li key={allergen.code} className="mr-4">
                  {allergen.code}: {allergen.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <button
        onClick={scrollToTop}
        className="fixed bottom-4 right-4 bg-primary text-white p-3 rounded-full shadow-lg hover:bg-primary-dark transition-all duration-300"
      >
        ↑
      </button>
      {/* Warenkorb anzeigen */}
      <Cart cart={cart} removeFromCart={removeFromCart} disablePayPal={true} />
      {notification && (
        <div className="notification fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-3 rounded shadow-lg">
          {notification}
        </div>
      )}
    </div>
  );
}

export default App;