import React, { useState, useMemo, useEffect } from 'react';
import allergens from './allergens'; // Importiere die Allergene
import { v4 as uuidv4 } from 'uuid';
import emailjs from 'emailjs-com';

const Cart = ({ cart, removeFromCart, disablePayPal }) => {
    const [showAllergens, setShowAllergens] = useState(false); // Zustand zum Umschalten der Allergenanzeige
    const [isExpanded, setIsExpanded] = useState(false); // Zustand für die erweiterte Ansicht
    const [cartItems, setCartItems] = useState(cart); // Zustand für den Warenkorb
  
  // Funktion zum Abrufen der Allergenbeschreibung
  const getAllergenDescription = (code) => {
    const allergen = allergens.find(a => a.code === code);
    return allergen ? allergen.description : '';
  };

  // Berechne die Gesamtsumme
  const total = useMemo(() => {
    return cart.reduce((sum, item) => sum + (item.selectedPrice !== '-' ? parseFloat(item.selectedPrice) * item.quantity : 0), 0).toFixed(2);
  }, [cart]);

  // Berechne die Gesamtanzahl der Produkte im Warenkorb
  const totalItems = useMemo(() => {
    return cart.reduce((sum, item) => sum + item.quantity, 0);
  }, [cart]);
  const handleCheckout = () => {
    const orderId = uuidv4(); // Generiere eine eindeutige ID für die Bestellung
    const orderDetails = cartItems.map(item => `${item.quantity}x ${item.name} (${item.variantLabel || ''}) - ${item.selectedPrice} €`).join('\n');
    const emailParams = {
      order_id: orderId,
      order_details: orderDetails,
      total: total,
      to_email: 'info@dsg.vision'
    };
  
    emailjs.send('service_3uthqwn', 'template_igtjh4t', emailParams, 'YJlBgS1F1gytIampD')
      .then((response) => {
        alert('Bestellung erfolgreich abgeschickt!');
        // Leere den Warenkorb nach erfolgreichem Versand
        setCartItems([]);
        localStorage.removeItem('cart');
      }, (error) => {
        alert('Fehler beim Abschicken der Bestellung. Bitte versuchen Sie es erneut.');
      });
  };
  useEffect(() => {
    if (!disablePayPal) {
      // Laden des PayPal SDK
      const script = document.createElement('script');
      script.src = "https://www.paypal.com/sdk/js?client-id=AQU2SaK2zqW4uCgrnGB37W8rC3P0mhisNXGhaVnn68ku3Od1EH1ZkRGnkqz0KlBdVmoTXM4gGdDIrDeF"; // Ersetzen Sie YOUR_CLIENT_ID durch Ihre PayPal-Client-ID
      script.addEventListener('load', () => {
        window.paypal.Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: total
                }
              }]
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then(details => {
              alert('Transaktion abgeschlossen von ' + details.payer.name.given_name);
              // Hier können Sie die Logik zum Abschließen der Bestellung hinzufügen
            });
          }
        }).render('#paypal-button-container');
      });
      document.body.appendChild(script);
    }
  }, [total, disablePayPal]);

  return (
    <div className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 ${isExpanded ? 'h-96' : 'h-16'} w-full max-w-md bg-primary dark:bg-gray-700 p-4 rounded-t-3xl shadow-lg transition-all duration-300 neon-effect`}>
      <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex items-center">
          <span className="mr-2 text-white">{isExpanded ? '▼' : '▲'}</span>
          <h2 className="text-xl font-bold text-white dark:text-white">Warenkorb</h2>
        </div>
        <div className="flex items-center">
          <span className="bg-red-500 text-white px-2 py-1 rounded mr-2">{totalItems}</span>
          <span className="text-lg font-bold text-white dark:text-white">{total} €</span>
        </div>
      </div>
      {isExpanded && (
        <div className="overflow-auto max-h-80 mt-4">
          <ul>
            {cart.map((item, index) => (
              <React.Fragment key={index}>
                <li className="text-gray-700 dark:text-gray-300 mb-2">
                  <div className="flex justify-between items-center">
                    <span>{item.quantity}x {item.name} {item.variantLabel && `(${item.variantLabel})`}</span>
                    <span className="ml-auto text-right">{item.selectedPrice} €</span>
                    <button
                      onClick={() => removeFromCart(index)}
                      className={`bg-red-500 text-white ml-2 mr-2 px-2 py-1 rounded ${isNaN(item.selectedPrice) ? 'cursor-not-allowed opacity-50' : ''}`}
                      disabled={isNaN(item.selectedPrice)}
                    >
                      x
                    </button>
                  </div>
                  {item.dressing && (
                    <div className="text-sm text-gray-500 dark:text-gray-400 ml-2">
                      Dressing: {item.dressing}
                    </div>
                  )}
                  {item.option && (
                    <div className="text-sm text-gray-500 dark:text-gray-400 ml-2">
                      Option: {item.option}
                    </div>
                  )}
                  {item.softdrink && (
                    <div className="text-sm text-gray-500 dark:text-gray-400 ml-2">
                      Softdrink: {item.softdrink}
                    </div>
                  )}
                  {showAllergens && item.allergens && (
                    <ul className="text-xs text-red-500 dark:text-red-300 ml-2">
                      {item.allergens.split(',').map((code) => (
                        <li key={code}>{getAllergenDescription(code)}</li>
                      ))}
                    </ul>
                  )}
                </li>
                {index < cart.length - 1 && <hr className="my-2 border-dashed border-gray-400" />}
              </React.Fragment>
            ))}
          </ul>
          <button
            onClick={() => setShowAllergens(!showAllergens)}
            className="mt-4 bg-white text-primary px-4 py-2 rounded"
          >
            {showAllergens ? 'Allergene ausblenden' : 'Allergene anzeigen'}
          </button>
          {/* <button
            onClick={handleCheckout}
            className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
            >
            Kostenpflichtig bestellen
            </button> */}
          {!disablePayPal && (
            <div id="paypal-button-container" className="mt-4"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;